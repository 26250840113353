import { Action } from '@ngrx/store';
import { AccountPlan } from 'src/app/_interface/account-tier.types';
import { CouponDetails, PaymentBillingInfo, PaymentInfo, SelectedPlanDetail } from './tier-upgrade.reducer';

export const SET_SELECTED_TIER = 'SET_SELECTED_TIER';
export const SET_TIER_VARIANTS = 'SET_TIER_VARIANTS';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const UPDATE_STEPS_COMPLETED = 'UPDATE_STEPS_COMPLETED';
export const SET_PROCEED_FOR_PAYMENT = 'SET_PROCEED_FOR_PAYMENT';
export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO';
export const SET_PAYMENT_BILLING_INFO = 'SET_PAYMENT_BILLING_INFO';
export const SET_SELECTED_PLAN_TYPE = 'SET_SELECTED_PLAN_TYPE';
export const SET_COUPON_DETAILS = 'SET_COUPON_DETAILS';
export const IS_LOADING = 'IS_LOADING';
export const DONATE_DATA_MEMBERSHIP = 'DONATE_DATA_MEMBERSHIP';

export class SetSelectedTier implements Action {
    readonly type = SET_SELECTED_TIER;
    constructor(public payload: AccountPlan) {}
}

export class SetTierVariants implements Action {
    readonly type = SET_TIER_VARIANTS;
    constructor(public tierDetailsOf: string, public payload: AccountPlan[]) {}
}

export class UpdateStepsCompleted implements Action {
    readonly type = UPDATE_STEPS_COMPLETED;
    constructor(public payload: number) {}
}

export class SetCurrentStep implements Action {
    readonly type = SET_CURRENT_STEP;
    constructor(public payload: number) {}
}

export class SetProceedForPayment implements Action {
    readonly type = SET_PROCEED_FOR_PAYMENT;
    constructor(public payload: boolean) {}
}

export class SetPaymentInfo implements Action {
    readonly type = SET_PAYMENT_INFO;
    constructor(public payload: PaymentInfo) {}
}

export class SetPaymentBillingInfo implements Action {
    readonly type = SET_PAYMENT_BILLING_INFO;
    constructor(public payload: PaymentBillingInfo) {}
}

export class SetSelectedPlanType implements Action {
    readonly type = SET_SELECTED_PLAN_TYPE;
    constructor(public payload: SelectedPlanDetail) {}
}

export class SetCouponDetails implements Action {
    readonly type = SET_COUPON_DETAILS;
    constructor(public payload: CouponDetails) {}
}

export class SetIsLoading implements Action {
    readonly type = IS_LOADING;
    constructor(public payload: boolean) {}
}
export class SetDonateData implements Action {
    readonly type = DONATE_DATA_MEMBERSHIP;
    constructor(public payload: {questId: number; user: number; amount: number; tipAmount: number;}) {}
}

export type TierUpgradeActions =     SetSelectedTier |
        SetTierVariants |
        UpdateStepsCompleted |
        SetCurrentStep |
        SetProceedForPayment |
        SetPaymentInfo |
        SetPaymentBillingInfo |
        SetSelectedPlanType |
        SetCouponDetails |
        SetIsLoading |
        SetDonateData;
