import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AccountPlan } from '../_interface/account-tier.types';
import { ReaquestHeadersService } from './requestHeaders.service';
import { HTTPStatus } from '../interceptors/loader.interceptor';

@Injectable({
  providedIn: 'root',
})
export class AccountTierService {

  displayPlanModalSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  displayPlanModal$ = this.displayPlanModalSubj.asObservable();

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private httpStatus: HTTPStatus
  ) { }

  getAccountTierPlans(): Observable<AccountPlan[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<AccountPlan[]>(
          environment.target + environment.context + '/plan/allPlans',
          {headers: headers}
      );
  }

  purchaseTierPlan(userId: number, planDetail: any): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    let payload = {
      recurrent: true,
      interval: planDetail.interval,
      paymentMode: planDetail.paymentMode,
      lastFour: planDetail.lastFour,
      token: planDetail.token,
      plan: planDetail.plan,
      couponCode: planDetail.couponCode,
      questId: planDetail.questId || null,
      donationAmount: planDetail.donationAmount || 0
    };

    this.httpStatus.setHttpStatus(true);
    return this.http.post<AccountPlan[]>(
          environment.target + environment.context + `/accounttier/purchaseMembership/${userId}`,
          payload,
          {headers: headers}
      );
  }

  validateCoupon(coupon: string): Observable<ValidateCoupon> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get<ValidateCoupon>(
      environment.target + environment.context + `/accounttier/couponcode/${coupon}`,
      {headers: headers}
    )
  }
  
  changeTierModalDisplay(val: boolean) {
    this.displayPlanModalSubj.next(val);
  }
}

interface ValidateCoupon {
  Coupon: string;
  code: string;
  endDate: number;
  freeTrialDays: number;
  id: number;
  startDate: number;
}