import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ExploreCardListType } from 'src/app/_interface/explore-page.types';
import { SearchResponse, SearchResult } from 'src/app/_interface/search.types';
import { QuestService } from 'src/app/_services/quest.service';
import { SearchService } from 'src/app/_services/search.service';
import { MyQuestsSevice } from '../../pages/my-quests/my-quests.service';
import { LoginPlatformToCompany, ResetLoginToPlatform, SelectFormMode, TryLogout } from '../auth/store/auth.actions';
import * as fromAuth from '../auth/store/auth.reducer';
import { DlLogInComponent } from '../dl-auth/dl-log-in/dl-log-in.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { QuestPreparedPhoto } from '../quest/quest.type';
import * as fromApp from './../../../_store/app.reducers';
import * as fromProfile from './../../pages/profile/store/profile.reducer';
import { NotificationsService } from './../notifications/notifications.service';
// import * as NotificationsActions from './../notifications/store/notifications.actions';
import { AccountTierService } from 'src/app/_services/account-tier.service';
import { GROUP_PLAN_ID_NAME } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import * as fromQuest from '../../../_interface/store/quest-detail.reducers';
import { ProfileService } from '../../pages/profile/profile.service';
import { QuestCreateComponent } from '../../quest-manage/quest-create/quest-create.component';
import { CalledNotificationApi, GetNotifications, MarkAllNotificationRead, SetUserQuests } from '../notifications/store/notifications.actions';

@Component({
  selector: '[id="header"]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();
  
  @ViewChild('dropdownElementRef', { read: ElementRef }) dropdownElementRef: ElementRef;
  
  @ViewChild('profileMenuElement', { read: ElementRef }) profileMenuElement: ElementRef;
  
  @ViewChild('notificationMenuElement', { read: ElementRef }) notificationMenuElement: ElementRef;
  
  @ViewChild('navDropdownElem', { read: ElementRef }) navDropdownElem: ElementRef;
  
  @ViewChild('notAuthenticatedHamburgerElem', { read: ElementRef }) notAuthenticatedHamburgerElem: ElementRef;
  
  notificationsCount: number = 0;
  
  authenticated: boolean = false;

  showSubMenuOpt = false;
  private searchChangesSubscription: Subscription;
  private searchResultsSubscription: Subscription;
  searchText = '';
  isLoading = false;
  isLoadingMore = false;
  isOpened = false;
  loadmoreEnabled = false;
  startIndex = 0;
  newContentLoaded = false;
  usersQuests: { [key: number]: ExploreCardListType } = {};
  limit = 15;
  results: SearchResult[] = [];
  searchForm: UntypedFormGroup;
  private cleared$: Subject<void> = new Subject();
  showProfileMenu = false;
  menuIsOpened = false;
  userInfo = null;
  showNotificationsMenu = false;

  tierLabel: string = 'Upgrade';

  openSearchMobile = false;

  searchApi: any = null;

  questState: Observable<fromQuest.QuestDetailState>;

  showMinimalHeader: boolean = false;

  authState: Observable<fromAuth.State>;

  @ViewChild('searchFieldWeb', {read: ElementRef, static: false}) searchFieldWeb: ElementRef;

  @ViewChild('searchFieldMobile', {read: ElementRef, static: false}) searchFieldMobile: ElementRef;
  
  @ViewChild('createIconOptionsElem', {read: ElementRef, static: false}) createIconOptionsElem: ElementRef;
  
  @ViewChild('createIconOptionsElemTab', {read: ElementRef, static: false}) createIconOptionsElemTab: ElementRef;

  showCreateIconOptions: boolean = false;

  showCreateIconTabOptions: boolean = false;

  hideMenuItems: boolean = false;

  membershipOf: string = null;

  companyList: any[] = [];

  @ViewChild('managerCompaniesTemp', {read: TemplateRef}) managerCompaniesTemp: TemplateRef<any>;

  environment = environment;

  isCompanyListCalled: boolean = false;

  isOnHome: boolean = false;

  constructor(
    private router: Router,
    private store: Store<fromApp.AppState>,
    private notificationsService: NotificationsService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private searchService: SearchService,
    private myQuestsService: MyQuestsSevice,
    private questService: QuestService,
    private accountTierService: AccountTierService,
    @Inject ('isServer') private isServer: boolean,
    private profileService: ProfileService
  ) { 
    this.authState = store.select('auth');
  }

  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (this.dropdownElementRef && !this.dropdownElementRef.nativeElement.contains(event.target) && this.isOpened) {
      this.close();
    }

    if (this.profileMenuElement && !this.profileMenuElement.nativeElement.contains(event.target) && this.showProfileMenu) {
      this.showProfileMenu = false;
    }

    if (this.notificationMenuElement && !this.notificationMenuElement.nativeElement.contains(event.target) && this.showNotificationsMenu) {
      this.showNotificationsMenu = false;
    }

    if (this.navDropdownElem && !this.navDropdownElem.nativeElement.contains(event.target) && 
      this.notAuthenticatedHamburgerElem && !this.notAuthenticatedHamburgerElem.nativeElement.contains(event.target) && 
      this.menuIsOpened) {
      this.menuIsOpened = false;
    }

    if (this.createIconOptionsElem && !this.createIconOptionsElem.nativeElement.contains(event.target) && this.showCreateIconOptions) {
      this.showCreateIconOptions = false;
    }

    if (this.createIconOptionsElemTab && !this.createIconOptionsElemTab.nativeElement.contains(event.target) && this.showCreateIconTabOptions) {
      this.showCreateIconTabOptions = false;
    }

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        // console.log('129 end of route');
        this.hideMenuItems = false;
        this.membershipOf = null;
        this.isOnHome = this.router.url.toLowerCase().match('/home') && this.router.url.toLowerCase().match('/home').index > -1;

        if ((this.router.url.toLowerCase().match('/nex') && this.router.url.toLowerCase().match('/nex').index > -1) || 
          (this.router.url.toLowerCase().match('/b-art') && this.router.url.toLowerCase().match('/b-art').index > -1) ||
          (this.router.url.toLowerCase().match('/foreveryoungmoms') && this.router.url.toLowerCase().match('/foreveryoungmoms').index > -1) ||
          (this.router.url.toLowerCase().match('/foreveryoungseniors') && this.router.url.toLowerCase().match('/foreveryoungseniors').index > -1) ||
          (this.router.url.toLowerCase().match('/rr4l') && this.router.url.toLowerCase().match('/rr4l').index > -1)) {
          this.hideMenuItems = true;

          this.membershipOf = (this.router.url.toLowerCase().match('/nex') && this.router.url.toLowerCase().match('/nex').index > -1) ? 'nex' : ((this.router.url.toLowerCase().match('/b-art') && this.router.url.toLowerCase().match('/b-art').index > -1) ? 'b-art' : ((this.router.url.toLowerCase().match('/rr4l') && this.router.url.toLowerCase().match('/rr4l').index > -1) ? 'rr4l' : null));
        }
        else if (this.router.url.toLowerCase().match('/passwordreset') && this.router.url.toLowerCase().match('/passwordreset').index > -1 
          && this.router.url.toLowerCase().match('resetfor=') && this.router.url.toLowerCase().match('resetfor=').index > -1) {
            const splitArr = this.router.url.toLowerCase().split('resetfor=');
            if (splitArr.length > 1) {
              const _membershipOfId = splitArr[1].split('&')[0];
              const _membershipOfName = GROUP_PLAN_ID_NAME[_membershipOfId];
              this.membershipOf = _membershipOfName.toLowerCase() === 'nex' ? 'nex' : (_membershipOfName.toLowerCase() === 'b-art' ? 'b-art' : null);
            }
          }
      } else if (this.authenticated) {
        this.checkForMembershipManager();
      }

      this.cdr.detectChanges();
      // else if (this.authenticated && this.userInfo.accountTier && this.userInfo.accountTier.planGroupId) {
      //   this.membershipOf = (this.userInfo.accountTier.planGroupId === 3 || this.userInfo.accountTier.planGroupId === 6) ? 'nex' : (this.userInfo.accountTier.planGroupId === 2 ? 'b-art': null);
      // } 
      // else if(this.authenticated &&
      //   this.userInfo.isMembershipManager && this.userInfo.membershipManagerPlans && this.userInfo.membershipManagerPlans.length > 0 
      // ) {
      //   const _planGroupId = this.userInfo.membershipManagerPlans[0].planGroupId;
      //   this.membershipOf = (_planGroupId === 3 || _planGroupId === 6) ? 'nex' : (_planGroupId === 2 ? 'b-art': null);
      // }
    });

    this.searchForm = this.fb.group({
      search: ['']
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
          this.showMinimalHeader = this.router.url.search('/home') < 0 &&
                                    this.router.url.search('/business') < 0 &&
                                    this.router.url.search('/dei') < 0 &&
                                    this.router.url.search('/esg') < 0;
      }
    });

    this.questState = this.store.select('quest');

    this.store
      .pipe(
        select('auth'),
        distinctUntilKeyChanged('authenticated'),
        map((state: fromAuth.State) => {
          return state.authenticated;
        }),
        takeUntil(this.destroyed$),
        tap(authenticated => {
          this.authenticated = authenticated;

          if (this.authenticated) {
            setTimeout(() => {
              this.getUserQuests();
            }, 800);
          }

          this.cdr.detectChanges();
        })
      )
      .subscribe();

    let originalUserInfoData;
    this.store
      .pipe(
        select('userInfo'),
        map((state: fromProfile.State) => {
          return state;
        }),
        takeUntil(this.destroyed$),
        tap(_state => {
          this.userInfo = _state;
          
          // console.log('243', _state && _state.id, originalUserInfoData && originalUserInfoData.id, originalUserInfoData && originalUserInfoData.id && _state.id && originalUserInfoData.id !== _state.id);
          if (originalUserInfoData && originalUserInfoData.id && _state.id && originalUserInfoData.id !== _state.id) {
            this.router.navigate(['/quests']);
            window.location.reload();
            
            return;
          }

          if (_state && _state.id) originalUserInfoData = _state;

          setTimeout(() => {
            if (!this.isCompanyListCalled && _state.id) {
              this.isCompanyListCalled = true;
              // console.log('254 calling company list');
              if (localStorage.getItem('stateUserInfoOrig')) {
                // console.log('256 from local storage');
                const origUserInfo = JSON.parse(localStorage.getItem('stateUserInfoOrig'));
                this.getCompanyList(origUserInfo.id);
              }
              else {
                // console.log('260 from store');
                this.getCompanyList(_state.id);
              }
            }
          }, 500);
          
          if ((this.userInfo && this.userInfo !== null && this.userInfo.id && this.userInfo.id !== null)) {
            this.store.select('notifications').pipe(take(1)).subscribe(notifState => {
              if (!notifState.hasCalledNotificationApi) {
                this.store.dispatch(new CalledNotificationApi(true));

                this.store.dispatch(new GetNotifications(_state.id, 0, 10));
                // this.notificationsService.getNotifications(_state.id, 0, 10).subscribe((res: NotificationType[]) => {
                //   this.store.dispatch(new SetNotifications(res));
    
                //   let unreadCount = 0;
                //   res.forEach(notif => {
                //     !notif.isRead ? unreadCount += 1 : null;
                //   });
    
                //   this.store.dispatch(new SetNotificationsInfo(unreadCount));
                // });
              }
            });

            if (this.userInfo.accountTier && this.userInfo.accountTier.level) {
              this.tierLabel = (this.userInfo.accountTier.level).toLowerCase();
              // this.membershipOf = (this.userInfo.accountTier.planGroupId === 3 || this.userInfo.accountTier.planGroupId === 6) ? 'nex' : (this.userInfo.accountTier.planGroupId === 2 ? 'b-art' : null);
            } else {
              this.tierLabel = 'Upgrade';
            }

            this.checkForMembershipManager();
          }

          this.cdr.detectChanges();
        })
      )
      .subscribe();
      
    // this.store
    //   .pipe(
    //     select('notifications'),
    //     takeUntil(this.destroyed$),
    //     tap((res: fromNotifications.State) => {
    //       this.notificationsInfo = res;
    //     })
    //   )
    //   .subscribe();

    this.store
      .pipe(
        select('notifications'),
        distinctUntilKeyChanged('count'),
        takeUntil(this.destroyed$),
        map((state) => state.count),
        tap(count => {
          this.notificationsCount = count;
          this.cdr.detectChanges();
        })
      )
      .subscribe();

  }
  ngOnDestroy(): void {
    this.isCompanyListCalled = false;
    this.destroyed$.next();
    this.destroyed$.complete();
    // this.store.dispatch(new NotificationsActions.ResetNotifications());
  }

  markedNotifRead(event) {
    if (event) {
      this.notificationsCount -= 1;
    }
  }

  openModal(openOf: string, mode?: string) {
    if (this.isServer) return;
    
    this.menuIsOpened = false;
    if (openOf === 'notifications-modal') {
      let modalInst = this.modalService.open(NotificationsComponent, {
        scrollable: false
      });
      modalInst.componentInstance.userId = this.userInfo.id;
      
      return;
    }

    this.modalService.open(DlLogInComponent, {
      scrollable: false,
      windowClass: "no-overflow login-modal"
    });
    setTimeout(() => {
      this.store.dispatch(new SelectFormMode(fromAuth.FormMode[mode || 'logIn']));
    }, 100);
  }

  onFocus(isMobile?: boolean): void {
    if (!this.searchChangesSubscription || this.searchChangesSubscription.closed) {
      this.searchChangesSubscription = this.subscribeForSearchChanges(isMobile);
    }
    if (!this.searchResultsSubscription || this.searchResultsSubscription.closed) {
      this.searchResultsSubscription = this.subscribeForSearchResultChanges();
    }
    
    if (!this.searchText) {
      this.isLoading = true;
      this.isLoadingMore = false;
      this.cdr.detectChanges();

      this.loadMore(true, true, false);
    }

    if (!isMobile) this.open();
  }

  close() {
    // if (this.isItemClicked) return;
    this.isOpened = false;
    this.clear();
  }

  private clear(): void {
    if (this.searchChangesSubscription) {
      this.searchChangesSubscription.unsubscribe();
    }
    if (this.searchResultsSubscription) {
      this.searchResultsSubscription.unsubscribe();
    }
    if (this.searchFieldWeb && this.searchFieldWeb.nativeElement) {
      this.searchFieldWeb.nativeElement.blur();
    }
    if (this.searchFieldMobile && this.searchFieldMobile.nativeElement) {
      this.searchFieldMobile.nativeElement.blur();
    }
    
    this.cleared$.next();
    this.cleared$.complete();
    this.searchForm.patchValue({
      search: ''
    }, {
      onlySelf: true,
      emitEvent: false
    });
    this.searchText = '';
    this.isLoading = false;
    this.isLoadingMore = false;

    this.cdr.detectChanges();
  }

  private subscribeForSearchChanges(isMobile?: boolean): Subscription {
    // * open search box if text in input is changed
    return this.searchForm.get('search').valueChanges
    .pipe(takeUntil(this.cleared$))
    .subscribe(() => {
      // this.isItemClicked = false;
      this.isOpened = true;
      if (!this.isOpened && !isMobile) {
        this.open();
      }

      this.cdr.detectChanges();
    });
  }

  private subscribeForSearchResultChanges(): Subscription {    
    return this.searchForm.get('search').valueChanges.pipe(
      // debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.cleared$),
      switchMap(val => {
        this.searchText = val;
        this.resetResults();
        this.isLoading = true;
        this.isLoadingMore = false;
        this.cdr.detectChanges();
        return this.forceLoadMore();
      })
    ).subscribe((searchResponse: SearchResponse) => {
      this.updateResults(searchResponse);
    }, (err) => {
      console.log(err);
      this.isLoading = false;
      this.isLoadingMore = false;
      this.cdr.detectChanges();
    });
  }

  private updateResults(searchResponse: SearchResponse): void {
    if (searchResponse.data) {
      this.loadmoreEnabled = searchResponse.more;
      this.startIndex = searchResponse.start + searchResponse.limit;
      this.results = [...this.results, ...searchResponse.data];
      
      setTimeout(() => {
        this.newContentLoaded = true;
        this.cdr.detectChanges();
      });
    }
    this.isLoadingMore = false;
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  private getUserQuests(): void {
    forkJoin([
      this.myQuestsService.getAllActiveQuestsForUser(),
      this.myQuestsService.getQuestsCompletedForUser()
    ]).subscribe(([activeQuests, completedQuests]) => {
      [...activeQuests, ...completedQuests].forEach(quest => {
        if (!this.usersQuests.hasOwnProperty(quest.id)) {
          this.usersQuests[quest.id] = quest;
        }
      });

      this.store.dispatch(new SetUserQuests(this.usersQuests));

      this.cdr.detectChanges();
    });
  }

  private resetResults(): void {
    this.loadmoreEnabled = true;
    this.startIndex = 0;
    this.results = [];
    this.cdr.detectChanges();
  }
  
  private forceLoadMore(): Observable<SearchResponse> {
    if (this.searchApi) this.searchApi.unsubscribe();
    
    // if (this.searchText === '' || !this.searchText) {
    //   return new Observable(null);
    // }

    return this.searchService.getSearchResults({
      q: this.searchText.toLocaleLowerCase(),
      _start: this.startIndex,
      _limit: this.limit,
      type: 'global'
    }).pipe(
      map((res: SearchResponse) => {
        if (res.data) {
          res.data.map((element, i) => {
            if (element.type === 'quest' && ((element.questId === 2907 && !environment.production) || (element.questId === 1332 && environment.production))) {
              res.data.splice(i, 1);
            }
          });
        }

        return res;
      })
    );
  }

  loadMore(resetResults: boolean, showCentralSpinner: boolean, showBottomSpinner: boolean): any {
    if (resetResults) {
      this.resetResults();
    }
    if (this.loadmoreEnabled) {
      if (showCentralSpinner) {
        this.isLoading = true;
      }
      if (showBottomSpinner) {
        this.isLoadingMore = true;
      }
      this.cdr.detectChanges();

      // .pipe(take(1))
      this.searchApi = this.forceLoadMore().subscribe((searchResponse: SearchResponse) => {
        this.updateResults(searchResponse);
      }, (err) => {
        console.log(err);
        this.isLoading = false;
        this.isLoadingMore = false;
        this.cdr.detectChanges();
      });
    }
  }

  open(): void {
    this.isOpened = true;
    this.cdr.detectChanges();
  }

  prepareCoverPhoto(photo, category): QuestPreparedPhoto {
    return this.questService.prepareCoverPhoto(photo, category);
  }

  selectItem(index: number): void {
    const selected = this.results[index];
    let url = [];

    if (selected.type === 'quest') {
      // tslint:disable-next-line: max-line-length
      // url = ['/quest-detail', selected.questId, (this.usersQuests.hasOwnProperty(selected.questId) ? this.usersQuests[selected.questId].seoSlugs.userId : selected.userId)];
      url = [selected.path]; //['/quest-detail', selected.questId, selected.userId];
    } else if (selected.type === 'user') {
      url = ['/profile', selected.userId];
    }
    this.router.navigate(url);
    this.menuIsOpened = false;
    this.openSearchMobile = false;

    this.close();
    this.cdr.detectChanges();
  }

  toggleProfileMenu() {
    this.showProfileMenu = !this.showProfileMenu;
  }

  toggleNotificationsMenu() {
    this.showNotificationsMenu = !this.showNotificationsMenu;
  }

  logoutUser() {
    this.menuIsOpened = false;
    this.showProfileMenu = false;
    this.isCompanyListCalled = false;
    this.store.dispatch(new TryLogout());
  }

  toggleSearchForMobile() {
    this.openSearchMobile = !this.openSearchMobile;
    this.close();
  }

  clearSearchForMobile() {
    this.cleared$.next();
    this.cleared$.complete();
    this.searchForm.patchValue({
      search: ''
    }, {
      onlySelf: true,
      emitEvent: false
    });
    this.searchText = '';
    this.isLoading = false;
    this.isLoadingMore = false;
    this.results = [];

    this.cdr.detectChanges();
  }

  showPlanModal() {
    // this.accountTierService.displayPlanModal = true;
    this.accountTierService.changeTierModalDisplay(true);
  }

  markAllNotificationRead() {
    this.notificationsService.markAllAsRead(this.userInfo.id)
      .subscribe(res => {
        this.store.dispatch(new MarkAllNotificationRead())
      }, err => {
        console.log('e', err)
      })
  }

  toggleCreateIcon() {
    this.showCreateIconOptions = !this.showCreateIconOptions;
    this.showCreateIconTabOptions = !this.showCreateIconTabOptions;

    this.cdr.detectChanges();
  }

  openCreateQuest(creationOf: string = 'quest'): void {
    //  create-quest-modal
    let modalReference = this.modalService.open(QuestCreateComponent, {
      windowClass: "no-overflow"
    });

    modalReference.componentInstance.creationOf = creationOf;
  }
  
  checkForMembershipManager() {
    this.membershipOf = null;
    if (this.authenticated && this.userInfo.accountTier && this.userInfo.accountTier.planGroupId) {
      this.membershipOf = (this.userInfo.accountTier.planGroupId === 3 || this.userInfo.accountTier.planGroupId === 6) ? 'nex' : (this.userInfo.accountTier.planGroupId === 2 ? 'b-art': null);
    } 
    else if(this.authenticated &&
      this.userInfo.isMembershipManager && this.userInfo.membershipManagerPlans && this.userInfo.membershipManagerPlans.length > 0 
    ) {
      const _planGroupId = this.userInfo.membershipManagerPlans[0].planGroupId;
      this.membershipOf = (_planGroupId === 3 || _planGroupId === 6) ? 'nex' : (_planGroupId === 2 ? 'b-art': null);
    }

    this.cdr.detectChanges();
  }

  getCompanyList(userId: number) {
    if (!userId) return;

    this.profileService.getCompanyList(userId)
      .subscribe((res: any) => {
        this.companyList = [...res];

        this.cdr.detectChanges();
      }, () => {
        this.companyList = [];
        this.isCompanyListCalled = false;
      })
  }

  openManagerCompanies(): void {
    this.modalService.open(this.managerCompaniesTemp, {
      windowClass: 'no-overflow confirm-modal'
    });
  }

  goToProfile(companyId: number): void {
    this.modalService.dismissAll();
    
    this.router.navigate(['/profile', companyId]);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  loginAsManager(companyItem: any): void {
    let clickedOnPlatform: boolean = false;
    let mainUserId = null;
    if (localStorage.getItem('stateUserInfoOrig')) {
      // check if user has clicked on the original company/platform manager
      const origUserInfo = JSON.parse(localStorage.getItem('stateUserInfoOrig'));
      mainUserId = origUserInfo.id;
      if (origUserInfo.id === companyItem.userId) {
        clickedOnPlatform = true;
        this.isCompanyListCalled = false;

        this.store.dispatch(new ResetLoginToPlatform());

        return;
      }
    }

    if (clickedOnPlatform) {
      return;
    }

    // copy the original state user info and token info in local storage
    if (!localStorage.getItem('stateAuthOrig')) {
      localStorage.setItem('stateAuthOrig', localStorage.getItem('stateAuth'));
      localStorage.setItem('stateUserInfoOrig', localStorage.getItem('stateUserInfo'));
    }
    
    this.isCompanyListCalled = false;

    // call the api to re-login and get token for the selected company
    this.store.select('auth').pipe(take(1)).subscribe(_authInfo => {
      this.store.select('userInfo').pipe(take(1)).subscribe(_userInfo => {
        // console.log('701 changing login', mainUserId, _userInfo.id);
        this.store.dispatch(new LoginPlatformToCompany({
          userId: mainUserId ? mainUserId : _userInfo.id, 
          companyId: companyItem.companyId, 
          currentCompanyId: _authInfo.newCompanyId
        }));

        // this.store.dispatch(new ClearUserInfo());
      });
    });
  }

  companyTrackBy(id, company) {
    return company.userId;
  }
}
