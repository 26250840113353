import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth-guard.service';

const appRoutes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule),
    data: [{ hideHeader: true }]
  },

  {
    path: 'business',
    loadChildren: () => import('./components/pages/new-landing/new-landing.module').then(m => m.NewLandingModule),
    data: [{ hideHeader: true }]
  },

  {
    path: 'esg',
    loadChildren: () => import('./components/pages/esg-landing/esg-landing.module').then(m => m.EsgLandingModule),
    data: [{ hideHeader: true }]
  },
  
  {
    path: 'dei',
    loadChildren: () => import('./components/pages/dei-landing/dei-landing.module').then(m => m.DeiLandingModule),
    data: [{ hideHeader: true }]
  },

  { 
    path: 'discover', 
    loadChildren: () => import('./modules/explore/explore.module').then(m => m.ExploreModule)
  },
  
  { 
    path: 'account', 
    loadChildren: () => import('./components/pages/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard] 
  },

  {
    path: 'profile',
    loadChildren: () => import('./components/pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },

  { 
    path: 'profile/:userId', 
    loadChildren: () => import('./components/pages/profile/profile.module').then(m => m.ProfileModule),
    pathMatch: 'prefix'
  },
  
  {
    path: 'create', 
    loadChildren: () => import('./components/pages/quest-manage/quest-manage.module').then(m => m.QuestManageModule),
    canActivate: [AuthGuard]
  },

  { 
    path: 'edit/:questId/:editType', 
    loadChildren: () => import('./components/pages/quest-manage/quest-manage.module').then(m => m.QuestManageModule),
    canActivate: [AuthGuard] 
  },

  {
    path: 'manage',
    loadChildren: () => import('./components/quest-manage/quest-manage.module').then(m => m.QuestManageModule),
    canActivate: [AuthGuard] 
  },

  // terms
  {
    path: 'legal',
    loadChildren: () => import('./components/pages/legal/legal.module').then(m => m.LegalModule)
  },

  // payments
  {
    path: 'donate/:questId/:userId/:teamId',
    loadChildren: () => import('./components/pages/donate/donate.module').then(m => m.DonateModule)
  },

  {
    path: 'get-tickets',
    loadChildren: () => import('./modules/ticket-flow/ticket-flow.module').then(m => m.TicketFlowModule)
  },
  // landing page
  { 
    path: 'passwordReset/:token', 
    loadChildren: () => import('./components/main/auth/password-reset/password-reset.module').then(m => m.PasswordResetModule)
  },

  { 
    path: 'passwordResetFail', 
    loadChildren: () => import('./components/main/auth/password-reset-fail/password-reset-fail.module').then(m => m.PasswordResetFailModule)
  },

  { 
    path: 'account-delete',
    loadChildren: () => import('./components/main/auth/delete-user/delete-user.module').then(m => m.DeleteUserModule)
  },

  { path: 'privacy', redirectTo: '/legal/privacy'},

  { 
    path: 'quest-detail-comments/:questId/:userId', 
    loadChildren: () => import('./components/pages/quest-detail-comments/quest-detail-comments.module').then(m => m.QuestDetailCommentsModule)
  },

  { 
    path: 'quest-detail/:questId/:userId/:teamId', 
    loadChildren: () => import('./components/pages/quest-detail/quest-detail.module').then(m => m.QuestDetailModule)
  },

  { 
    path: 'quest-detail/:questId/:userId', 
    redirectTo: 'quest-detail/:questId/:userId/0'
  },

  {
    path: ':userSlug/:questSlug/:questId/:userId/:teamId', 
    loadChildren: () => import('./components/pages/quest-detail/quest-detail.module').then(m => m.QuestDetailModule)
  },

  {
    path: ':userSlug/:questSlug/:questId/:userId', 
    redirectTo: ':userSlug/:questSlug/:questId/:userId/0'
  },

  {
    path: 'case-studies',
    loadChildren: () => import('./components/pages/use-cases/use-cases.module').then(m => m.UseCasesModule),
    data: [{ hideHeader: true }]
  },

  {
    path: 'foreveryoung',
    redirectTo: 'case-studies/foreveryoung'
  },

  {
    path: 'platform',
    loadChildren: () => import('./components/pages/platform/platform.module').then(m => m.PlatformModule),
    data: [{ hideHeader: true }]
  },

  {
    path: 'our-people',
    loadChildren: () => import('./components/pages/our-people/our-people.module').then(m => m.OurPeopleModule),
    data: [{ hideHeader: true }]
  },

  {
    path: 'nex',
    loadChildren: () => import('./components/pages/on-one/on-one.module').then(m => m.OnOneModule)
  },

  {
    path: 'nex-ind',
    redirectTo: 'nex'
  },
  
  {
    path: 'nex-corp',
    redirectTo: 'nex'
  },

  {
    path: 'f4p',
    loadChildren: () => import('./components/pages/f4p/f4p.module').then(m => m.F4pModule)
  },

  {
    path: 'b-art',
    loadChildren: () => import('./components/pages/bai/bai.module').then(m => m.BaiModule)
  },

  {
    path: 'rr4l',
    loadChildren: () => import('./components/pages/rr4l/rr4l.module').then(m => m.Rr4lModule)
  },

  {
    path: 'mqf',
    loadChildren: () => import('./components/pages/mqf/mqf.module').then(m => m.MqfModule)
  },

  {
    path: 'ppf',
    loadChildren: () => import('./components/pages/ppf/ppf.module').then(m => m.PpfModule)
  },

  {
    path: 'foreveryoungseniors',
    loadChildren: () => import('./components/pages/foreveryoungseniors/foreveryoungseniors.module').then(m => m.ForeverYoungSeniorsModule)
  },

  {
    path: 'foreveryoungmoms',
    loadChildren: () => import('./components/pages/foreveryoungmoms/foreveryoungmoms.module').then(m => m.ForeverYoungMomsModule)
  },

  {
    path: 'hertexas',
    loadChildren: () => import('./components/pages/hertexas/hertexas.module').then(m => m.HertexasModule)
  },

  {
    path: 'faqs',
    loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule),
    data: [{ hideHeader: true }]
  },

  {
    path: 'templates',
    loadChildren: () => import('./modules/templates/templates.module').then(m => m.TemplatesModule)
  },

  {
    path: 'upgrade',
    loadChildren: () => import('./components/pages/diemlife-upgrade/diemlife-upgrade.module').then(m => m.DiemlifeUpgradeModule)
  },
  
  {
    path: 'quests',
    loadChildren: () => import('./modules/my-quests/my-quests.module').then(m => m.MyQuestsModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'contact',
    pathMatch: 'full',
    redirectTo: 'home/contact'
  },

  {
    path: 'login',
    pathMatch: 'full',
    redirectTo: 'home/login'
  },

  {
    path: 'signup',
    pathMatch: 'full',
    redirectTo: 'home/signup'
  },

  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
