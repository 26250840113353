import { Action } from '@ngrx/store';
import { BrandConfig } from '../brand.types';
import { FundraisingLinkType } from '../fundraise-link.types';
import { LoggedActivity, Quest, QuestGalleryImage, QuestMembersApi } from '../quest.types';
import { QuestTeam, QuestTeamFundraise } from '../team.types';

export const SET_QUEST_ID = 'SET_QUEST_ID';
export const SET_QUEST_USER_ID = 'SET_QUEST_USER_ID';
export const SET_QUEST_TEAM_ID = 'SET_QUEST_TEAM_ID';
export const LOAD_BRANDS = 'LOAD_BRANDS';
export const BRANDS_LOADED = 'BRANDS_LOADED';
export const LOAD_TEAMS = 'LOAD_TEAMS';
export const TEAMS_LOADED = 'TEAMS_LOADED';
export const CREATE_TEAM = 'CREATE_TEAM';
export const TEAM_CREATED = 'TEAM_CREATED';
export const JOIN_TEAM = 'JOIN_TEAM';
export const TEAM_JOINED = 'TEAM_JOINED';
export const START_FUNDRAISER = 'START_FUNDRAISER';
export const FUNDRAISER_STARTED = 'FUNDRAISER_STARTED';
export const START_QUEST = 'START_QUEST';
export const QUEST_STARTED = 'QUEST_STARTED';
export const OPEN_TEAM_MODAL = 'OPEN_TEAM_MODAL';
export const CLOSE_TEAM_MODAL = 'CLOSE_TEAM_MODAL';
export const QUEST_LOGGED_ACTIVITIES = 'QUEST_LOGGED_ACTIVITIES';
export const UPDATE_QUEST_LOGGED_ACTIVITY = 'UPDATE_QUEST_LOGGED_ACTIVITY';
export const QUEST_NEW_LOGGED_ACTIVITY = 'QUEST_NEW_LOGGED_ACTIVITY';
export const SET_QUEST_GALLERY_ASSETS = 'SET_QUEST_GALLERY_ASSETS';
export const GET_QUEST_GALLERY_ASSETS = 'GET_QUEST_GALLERY_ASSETS';
export const SET_QUEST_DETAIL = 'SET_QUEST_DETAIL';
export const OPEN_LOG_ACTIVITY_MODAL = 'OPEN_LOG_ACTIVITY_MODAL';
export const UPDATE_TEAMS_WITHOUT_FUNDRAISING = 'UPDATE_TEAMS_WITHOUT_FUNDRAISING';
export const UPDATE_IS_DOER_FUNDRAISING_LINK = 'UPDATE_IS_DOER_FUNDRAISING_LINK';
export const PERFORM_ACTION_AFTER_LOAD = 'PERFORM_ACTION_AFTER_LOAD';
export const LOGGED_IN_USER_VERSION_EXISTS = 'LOGGED_IN_USER_VERSION_EXISTS';
export const OPEN_CREATE_TEAM_MODAL = 'OPEN_CREATE_TEAM_MODAL';
export const TEAM_MODAL_CONFIG = 'TEAM_MODAL_CONFIG';
export const CLEAR_TEAM_MODAL_CONFIG = 'CLEAR_TEAM_MODAL_CONFIG';
export const UPDATE_QUEST_MEMBERS_AND_COUNT = 'UPDATE_QUEST_MEMBERS_AND_COUNT';
export const SET_QUEST_LOGGED_USER_TEAM_ID = 'SET_QUEST_LOGGED_USER_TEAM_ID';
export const SET_PARENT_QUEST_TEAMS = 'SET_PARENT_QUEST_TEAMS';
export const IS_CURRENT_USER_ADMIN_OR_OWNER = 'IS_CURRENT_USER_ADMIN_OR_OWNER';
export const CLEAR_QUEST_DETAIL = 'CLEAR_QUEST_DETAIL';
export const UPDATE_IS_USER_QUEST_MEMBER = 'UPDATE_IS_USER_QUEST_MEMBER';
export const UPDATE_DOER_FUNDRAISING_LINK = 'UPDATE_DOER_FUNDRAISING_LINK';
export const UPDATE_CURRENT_USER_LINK = 'UPDATE_CURRENT_USER_LINK';
export const UPDATE_RELOAD_DATA_OF = 'UPDATE_RELOAD_DATA_OF';

export class SetQuestId implements Action {
  readonly type = SET_QUEST_ID;

  constructor(public payload: number) {
    console.log('QuestActions::SetQuestId', payload);
  }
}

export class SetQuestUserId implements Action {
  readonly type = SET_QUEST_USER_ID;

  constructor(public payload: number) {
    console.log('QuestActions::SetQuestUserId', payload);
  }
}

export class SetQuestTeamId implements Action {
  readonly type = SET_QUEST_TEAM_ID;

  constructor(public payload: number) {
    console.log('QuestActions::SetQuestTeamId', payload);
  }
}

export class LoadBrands implements Action {
  readonly type = LOAD_BRANDS;

  constructor(public questId: number) {
    console.log('QuestActions::LoadBrands', {questId});
  }
}

export class BrandsLoaded implements Action {
  readonly type = BRANDS_LOADED;

  constructor(public brands: BrandConfig[]) {
    console.log('QuestActions::BrandsLoaded', brands);
  }
}

export class LoadTeams implements Action {
  readonly type = LOAD_TEAMS;

  constructor(public questId: number) {
    console.log('QuestActions::LoadTeams', questId);
  }
}

export class TeamsLoaded implements Action {
  readonly type = TEAMS_LOADED;

  constructor(public teams: QuestTeam[]) {
    console.log('QuestActions::TeamLoaded', teams);
  }
}

export class CreateTeam implements Action {
  readonly type = CREATE_TEAM;

  constructor(public questId: number, public teamName: string, public teamLogoUrl: string, public redirectToMyQuests: boolean = true,
    public isFundraiser: boolean = false, public fundraisingData?: {
      questId: number,
      doerId: number,
      brandUserId: number,
      targetAmount: number,
      currency: string,
      campaignName: string,
      secondaryBrandUserId: number,
      teamId: number,
      coverImageUrl: string,
      coverCenterX: string,
      coverCenterY: string,
      coverZoomValue: string
    }) {
    console.log('QuestActions::CreateTeam', {questId, teamName, teamLogoUrl, redirectToMyQuests, isFundraiser, fundraisingData});
  }
}

export class TeamCreated implements Action {
  readonly type = TEAM_CREATED;

  constructor(public team: QuestTeam) {
    console.log('QuestActions::TeamCreated', team);
  }
}

export class JoinTeam implements Action {
  readonly type = JOIN_TEAM;

  constructor(public questId: number, public teamId: number) {
    console.log('QuestActions::JoinTeam', {questId, teamId});
  }
}

export class TeamJoined implements Action {
  readonly type = TEAM_JOINED;

  constructor(public team: QuestTeam) {
    console.log('QuestActions::TeamJoined', team);
  }
}

export class StartFundraiser implements Action {
  readonly type = START_FUNDRAISER;

  constructor(public questId: number,
              public userId: number,
              public brandUserId: number,
              public targetAmount: number,
              public currency: string,
              public campaignName: string,
              public secondaryBrandUserId?: number,
              public teamId?: number,
              public moveToMyQuests?: boolean,
              public teamData?: QuestTeam) {
    console.log('QuestActions::StartFundraiser', {
      questId,
      userId,
      brandUserId,
      secondaryBrandUserId,
      targetAmount,
      currency,
      campaignName,
      teamId
    });
  }
}

export class FundraiserStarted implements Action {
  readonly type = FUNDRAISER_STARTED;

  constructor(public link: FundraisingLinkType) {
    console.log('QuestActions::FundraiserStarted', link);
  }
}

export class StartQuest implements Action {
  readonly type = START_QUEST;

  constructor(public questId: number, public referrerId: number, public questMode: string, public fundraiserTitle?: string) {
    console.log('QuestActions::StartQuest', {questId, referrerId ,questMode, fundraiserTitle});
  }
}

export class QuestStarted implements Action {
  readonly type = QUEST_STARTED;

  constructor(public success: number) {
    console.log('QuestActions::QuestStarted', success);
  }
}

export class OpenTeamModal implements Action {
  readonly type = OPEN_TEAM_MODAL;

  constructor(public teamId: number) {
    console.log('QuestActions::OpenTeamModal', teamId);
  }
}

export class CloseTeamModal implements Action {
  readonly type = CLOSE_TEAM_MODAL;

  constructor() {
    console.log('QuestActions::CloseTeamModal');
  }
}

export class QuestLoggedActivities implements Action {
  readonly type = QUEST_LOGGED_ACTIVITIES;

  constructor(public payload: LoggedActivity[]) {
    console.log('QuestActions::QuestLoggedActivities');
  }
}

export class UpdateQuestLoggedActivity implements Action {
  readonly type = 'UPDATE_QUEST_LOGGED_ACTIVITY';

  constructor(public payload: LoggedActivity) {

  }
}

export class QuestNewLoggedActivity implements Action {
  readonly type = 'QUEST_NEW_LOGGED_ACTIVITY';

  constructor(public payload: LoggedActivity) {

  }
}

export class SetQuestGalleryAssets implements Action {
  readonly type = SET_QUEST_GALLERY_ASSETS;

  constructor(public payload: QuestGalleryImage[]) {}
}

export class GetQuestGalleryAssets implements Action {
  readonly type = GET_QUEST_GALLERY_ASSETS;

  constructor(public questId: number, public userId: number) {
    console.log('QuestDetail:GetQuestGalleryAssets')
  }
}

export class SetQuestData implements Action {
  readonly type = SET_QUEST_DETAIL;

  constructor(public payload: Quest) {}
}

export class OpenLogActivityModal implements Action {
  readonly type = OPEN_LOG_ACTIVITY_MODAL;

  constructor(public isOpen: boolean, public taskId?: number) {}
}

export class UpdateTeamsWithoutFundraising implements Action {
  readonly type = UPDATE_TEAMS_WITHOUT_FUNDRAISING;

  constructor(public payload: boolean) {}
}

export class UpdateDoerFundraisingLink implements Action {
  readonly type = UPDATE_DOER_FUNDRAISING_LINK;

  constructor(public payload: FundraisingLinkType) {}
}

export class UpdateCurrentUsersLink implements Action {
  readonly type = UPDATE_CURRENT_USER_LINK;

  constructor(public payload: FundraisingLinkType) {}
}

export class UpdateIsDoerFundraisingLink implements Action {
  readonly type = UPDATE_IS_DOER_FUNDRAISING_LINK;

  constructor(public payload: boolean) {
    console.log('QuestDetail::UpdateIsDoerFundraisingLink', payload);
  }
}

export class PerformActionAfterLoad implements Action {
  readonly type = PERFORM_ACTION_AFTER_LOAD;

  constructor(public payload: string) {}
}

export class LoggedInUserVersionExists implements Action {
  readonly type = LOGGED_IN_USER_VERSION_EXISTS;

  constructor(public payload: boolean) {}
}

export class ChangeOpenCreateTeamModal implements Action {
  readonly type = OPEN_CREATE_TEAM_MODAL;

  constructor(public payload: boolean) {}
}

export class SetTeamModalConfig implements Action {
  readonly type = TEAM_MODAL_CONFIG;

  constructor(public payload: QuestTeamFundraise) {}
}

export class ClearTeamModalConfig implements Action {
  readonly type = CLEAR_TEAM_MODAL_CONFIG;

  constructor() {}
}

export class UpdateQuestMembersAndCount implements Action {
  readonly type = UPDATE_QUEST_MEMBERS_AND_COUNT;

  constructor(public payload: QuestMembersApi) {
    console.log('QuestDetail::UpdateQuestMembersAndCount', payload);
  }
}

export class SetQuestLoggedUserTeamId implements Action {
  readonly type = SET_QUEST_LOGGED_USER_TEAM_ID;

  constructor(public payload: number) {}
}

export class SetParentQuestTeams implements Action {
  readonly type = SET_PARENT_QUEST_TEAMS;

  constructor(public payload: Quest[]) {}
}

export class SetIsCurrentUserAdminOrOwner implements Action {
  readonly type = IS_CURRENT_USER_ADMIN_OR_OWNER;

  constructor(public payload: boolean) {}
}

// export class ClearQuestDetail implements Action {
//   readonly type = CLEAR_QUEST_DETAIL;

//   constructor() {}
// }

export class UpdateIsUserQuestMember implements Action {
  readonly type = UPDATE_IS_USER_QUEST_MEMBER;

  constructor(public payload: boolean) {}
}

export class UpdateReloadDataOf implements Action {
  readonly type = UPDATE_RELOAD_DATA_OF;

  constructor(public payload: string[]) {}
}

export type QuestActions = SetQuestId
  | LoadBrands
  | BrandsLoaded
  | LoadTeams
  | TeamsLoaded
  | CreateTeam
  | TeamCreated
  | JoinTeam
  | TeamJoined
  | StartFundraiser
  | FundraiserStarted
  | StartQuest
  | QuestStarted
  | OpenTeamModal
  | CloseTeamModal
  | QuestLoggedActivities
  | UpdateQuestLoggedActivity
  | QuestNewLoggedActivity 
  | SetQuestGalleryAssets
  | GetQuestGalleryAssets
  | SetQuestData
  | OpenLogActivityModal
  | UpdateTeamsWithoutFundraising
  | UpdateIsDoerFundraisingLink
  | UpdateDoerFundraisingLink
  | UpdateCurrentUsersLink
  | SetQuestUserId 
  | SetQuestTeamId
  | PerformActionAfterLoad 
  | LoggedInUserVersionExists
  | ChangeOpenCreateTeamModal
  | SetTeamModalConfig
  | ClearTeamModalConfig
  | UpdateQuestMembersAndCount
  | SetQuestLoggedUserTeamId
  | SetParentQuestTeams 
  | SetIsCurrentUserAdminOrOwner
  | UpdateIsUserQuestMember
  | UpdateReloadDataOf;
