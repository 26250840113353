import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MapboxService } from 'src/app/_interface/service/mapbox.service';
import { DigitkPipe } from 'src/app/_pipes/digit-k.pipe';
import { KeysPipe } from 'src/app/_pipes/keys.pipe';
import { SafePipe } from 'src/app/_pipes/safe-pipe';
import { TimeAgoPipe } from 'src/app/_pipes/time-ago.pipe';
import { QuestService } from 'src/app/_services/quest.service';
import { ReferenceDataService } from 'src/app/_services/reference-data.service';
import { ReaquestHeadersService } from 'src/app/_services/requestHeaders.service';
import { SearchService } from 'src/app/_services/search.service';
import { BirthdayControlService } from 'src/app/components/main/birthday-control/birthday-control.service';
import { BrandListComponent } from 'src/app/components/main/brand/brand-list/brand-list.component';
import { CardComponent } from 'src/app/components/main/card/card.component';
import { DlMilestonesService } from 'src/app/components/main/dl-milestones/service/dl-milestones.service';
// import { TeamListItemComponent } from 'src/app/components/main/map-popover/team-list-item/team-list-item.component';
import { MembersDialogComponent } from 'src/app/components/main/members-dialog/members-dialog.component';
import { MembersComponent } from 'src/app/components/main/members/members.component';
import { SortMembersPipe } from 'src/app/components/main/members/sort-members.pipe';
import { PayDialogComponent } from 'src/app/components/main/pay-dialog/pay-dialog.component';
import { PhoneControlComponent } from 'src/app/components/main/phone-control/phone-control.component';
import { QuestProgressComponent } from 'src/app/components/main/quest/quest-progress/quest-progress.component';
import { AccountService } from 'src/app/components/pages/account/account.service';
import { CommonFooterComponent } from 'src/app/components/pages/common-footer/common-footer.component';
import { FriendsItemComponent } from 'src/app/components/pages/friends/friends-item/friends-item.component';
import { MyQuestsSevice } from 'src/app/components/pages/my-quests/my-quests.service';
import { NewLandingService } from 'src/app/components/pages/new-landing/new-landing.service';
import { ProfileAvatarComponent } from 'src/app/components/separate/profile-avatar/profile-avatar.component';
import { TeamAvatarComponent } from 'src/app/components/separate/team-avatar/team-avatar.component';
import { AuthGuardDirective } from 'src/app/directives/auth-guard/auth-guard.directive';
import { AuthGuardService } from 'src/app/directives/auth-guard/auth-guard.service';
import { BsSelectDirective } from 'src/app/directives/bs-select/bs-select.directive';
import { GetInViewDirective } from 'src/app/directives/get-in-view/get-in-view.directive';
import { LoadmoreTriggerDirective } from 'src/app/directives/loadmore-trigger/loadmore-trigger.directive';
import { SmartEditDirective } from 'src/app/directives/smart-edit/smart-edit.directive';
import { MobileSideNavService } from './components/mobile-side-nav/mobile-side-nav.service';
import { TierBadgeComponent } from './components/tier-badge/tier-badge.component';
import { UiControlComponent } from './components/ui/ui-control/ui-control.component';
import { UiSelectTeamService } from './elements/ui-select-team.service';
import { UiSelectTeamComponent } from './elements/ui-select-team/ui-select-team.component';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxGridModule } from '@egjs/ngx-grid';
import { NgMapsCoreModule } from '@ng-maps/core';
import { NgMapsDirectionModule } from '@ng-maps/direction';
import { NgMapsGoogleModule } from '@ng-maps/google';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { QuillModule } from 'ngx-quill';
import { QuestHeaderComponent } from '../../components/main/quest/quest-header/quest-header.component';
import { GalleryFullscreenComponent } from './components/gallery-fullscreen/gallery-fullscreen.component';
import { ImagePositionZoomComponent } from './components/image-position-zoom/image-position-zoom.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { FileDropDirective } from './directive/file-drop/file-drop.directive';

import {
  LY_THEME,
  LY_THEME_NAME,
  LyHammerGestureConfig,
  LyTheme2,
  StyleRenderer
} from '@alyle/ui';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { NgxTributeModule } from 'ngx-tribute';
import { WindowRef } from 'src/app/_global/window-ref.module';
import { AuthGuard } from 'src/app/_guards/auth-guard.service';
import { ReversePipe } from 'src/app/_pipes/reverse.pipe';
// import { CommonService } from 'src/app/_services/common.service';
import { ClipboardModule } from 'ngx-clipboard';
import { SmallCaseTextPipe } from 'src/app/_pipes/small-case-text.pipe';
import { LocalStorageService } from 'src/app/_services/localstorage.service';
import { LogActivityService } from 'src/app/_services/log-activity.service';
import { AuthService } from 'src/app/components/main/auth/auth.service';
import { PasswordResetFormComponent } from 'src/app/components/main/auth/password-reset-form/password-reset-form.component';
import { MembersService } from 'src/app/components/main/members/members.service';
import { NotificationsService } from 'src/app/components/main/notifications/notifications.service';
import { ShareWidgetModalComponent } from 'src/app/components/main/share-widget-modal/share-widget-modal.component';
import { ShareWidjetService } from 'src/app/components/main/share-widjet/share-widjet.service';
import { FriendsService } from 'src/app/components/pages/friends/friends.service';
import { ProfileService } from 'src/app/components/pages/profile/profile.service';
import { ProfileAvatarService } from 'src/app/components/separate/profile-avatar/profile-avatar.service';
import { CachedSrcDirective } from 'src/app/directives/cached-src-directive/cached-src.directive';
import { InputAutoHeightDirective } from 'src/app/directives/inputAutoheight/input-autoheight.directive';
import { MentionDirective } from 'src/app/directives/mention/mention.directive';
import { DLMyQuestsService } from '../my-quests/my-quests.service';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
/** Import themes */
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { MinimaDark, MinimaLight } from '@alyle/ui/themes/minima';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DlLogInComponent } from 'src/app/components/main/dl-auth/dl-log-in/dl-log-in.component';
import { UserSignupComponent } from 'src/app/components/main/dl-auth/user-signup/user-signup.component';
import { MilestoneTaskComponent } from 'src/app/components/main/dl-milestones/milestones-list/milestone-task/milestone-task.component';
import { CommentComponent } from 'src/app/components/pages/quest-detail/quest-comments/comment/comment.component';
import { CommentService } from 'src/app/components/pages/quest-detail/quest-comments/comment/comment.service';
import { NewsfeedCtrlComponent } from 'src/app/components/pages/quest-detail/quest-comments/newsfeed-ctrl/newsfeed-ctrl.component';
import { NewsfeedPostComponent } from 'src/app/components/pages/quest-detail/quest-comments/newsfeed-post/newsfeed-post.component';
import { QuestCommentItemComponent } from 'src/app/components/pages/quest-detail/quest-comments/quest-comment-item/quest-comment-item.component';
import { QuestCommentsComponent } from 'src/app/components/pages/quest-detail/quest-comments/quest-comments.component';
import { QuestCreateComponent } from 'src/app/components/quest-manage/quest-create/quest-create.component';
import { AlertBannerComponent } from './components/alert-banner/alert-banner.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgMapsGoogleModule.forRoot({
      apiKey: 'AIzaSyBFBBWND3Iwi097HWKyKAR4Z4oZpdPzMxk'
    }),
    NgMapsCoreModule,
    NgMapsDirectionModule.forRoot(),
    ReactiveFormsModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    NgbTooltipModule,
    NgbAlertModule,
    QuillModule.forRoot(),
    SlickCarouselModule,
    RouterModule,
    NgbDropdownModule,
    DragDropModule,
    NgxIntlTelInputModule,
    InfiniteScrollModule,
    NgSelectModule,
    NgScrollbarModule,
    NgxGridModule,
    NgbProgressbarModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxTributeModule,
    PasswordStrengthMeterModule.forRoot(),
    LyImageCropperModule,
    LyButtonModule,
    LyIconModule,
    HammerModule,
    ClipboardModule,
    NgxCaptchaModule
  ],
  declarations: [
    DlLogInComponent,
    UserSignupComponent,
    ReversePipe,
    CachedSrcDirective,
    PayDialogComponent,
    BsSelectDirective,
    AuthGuardDirective,
    GetInViewDirective,
    ShareWidgetModalComponent,
    CardComponent,
    PhoneControlComponent,
    InputAutoHeightDirective,
    SmartEditDirective,
    UiControlComponent,
    UiSelectTeamComponent,
    LoadmoreTriggerDirective,
    FriendsItemComponent,
    ProfileAvatarComponent,
    TeamAvatarComponent,
    QuestProgressComponent,
    TimeAgoPipe,
    SafePipe,
    KeysPipe,
    DigitkPipe,
    SmallCaseTextPipe,
    MentionDirective,
    CommonFooterComponent,
    TierBadgeComponent,
    BrandListComponent,
    SortMembersPipe,
    MembersDialogComponent,
    MembersComponent,
    ImagePositionZoomComponent,
    FileDropDirective,
    ImageUploadComponent,
    QuestHeaderComponent,
    GalleryFullscreenComponent,
    QuestCommentItemComponent,
    QuestCommentsComponent,
    NewsfeedCtrlComponent,
    NewsfeedPostComponent,
    CommentComponent,
    VideoPlayerComponent,
    PasswordResetFormComponent,
    AlertBannerComponent,
    MilestoneTaskComponent,
    QuestCreateComponent
  ],
  exports: [
    CommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReversePipe,
    CachedSrcDirective,
    PayDialogComponent,
    BsSelectDirective,
    AuthGuardDirective,
    NgbTooltipModule,
    ShareWidgetModalComponent,
    SlickCarouselModule,
    NgbDropdownModule,
    BrandListComponent,
    CardComponent,
    InputAutoHeightDirective,
    DragDropModule,
    BrandListComponent,
    NgxIntlTelInputModule,
    PhoneControlComponent,
    DlLogInComponent,
    UserSignupComponent,
    SmartEditDirective,
    UiControlComponent,
    UiSelectTeamComponent,
    LoadmoreTriggerDirective,
    FriendsItemComponent,
    ProfileAvatarComponent,
    TeamAvatarComponent,
    QuestProgressComponent,
    TimeAgoPipe,
    SafePipe,
    KeysPipe,
    DigitkPipe,
    SmallCaseTextPipe,
    CommonFooterComponent,
    TierBadgeComponent,
    SortMembersPipe,
    MembersComponent,
    ImagePositionZoomComponent,
    ImageUploadComponent,
    NgxGridModule,
    QuestHeaderComponent,
    QuestCommentsComponent,
    NewsfeedCtrlComponent,
    NewsfeedPostComponent,
    CommentComponent,
    VideoPlayerComponent,
    PasswordResetFormComponent,
    AlertBannerComponent,
    MembersDialogComponent,
    MilestoneTaskComponent,
    QuestCreateComponent
  ],
  providers: [
    AuthService,
    AuthGuard,
    LocalStorageService,
    WindowRef,
    ProfileService,
    // CommonService,
    NotificationsService,
    FriendsService,
    MembersService,
    CommentService,
    ShareWidjetService,
    ProfileAvatarService,
    QuestService,
    DLMyQuestsService,
    ReaquestHeadersService,
    ReferenceDataService,
    AuthGuardService,
    SearchService,
    NewLandingService,
    BirthdayControlService,
    DlMilestonesService,
    UiSelectTeamService,
    MyQuestsSevice,
    AccountService,
    MapboxService,
    MobileSideNavService,
    KeysPipe,
    provideNgxMask(),
    [ LyTheme2 ],
    [ StyleRenderer ],
    // Theme that will be applied to this module
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
    // Gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }, // Required for <ly-carousel>
    LogActivityService
  ],
  schemas: [NO_ERRORS_SCHEMA]
})

export class SharedModule { }
